import { socioGrpcClient } from "@/setup/socioGrpcClient";

export const retrieveBySocotecCode = async (
  socotecCode,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.user_management.user.UserRetrieveBySocotecCodeRequest,
      { socotecCode: socotecCode }
    );
    const response = await socioGrpcClient.user_management.user.UserControllerPromiseClient.retrieveBySocotecCode(
      request,
      metadata
    );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};
export const retrieveByUuid = async (
  uuid,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.user_management.user.UserRetrieveRequest,
      { uuid: uuid }
    );
    const response = await socioGrpcClient.user_management.user.UserControllerPromiseClient.retrieve(
      request,
      metadata
    );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const fetchUsers = async (uuids) => {
  let metadata = {}
  if (uuids) {
    metadata.filters = JSON.stringify({
      is_in_uuids: uuids,
    })
  }
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.user_management.user.UserListRequest,
    {}
  );
  const response = await socioGrpcClient.user_management.user.UserControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject().resultsList;
};

export const listUser = async (metadata) => {
  const request = new socioGrpcClient.user_management.user.UserListRequest();

  const response =
    await socioGrpcClient.user_management.user.UserControllerPromiseClient.list(
      request,
      metadata
    );

  return response.toObject();
}