import { messages } from "@/lang/messages/index";
import { aosMessages } from "@socotec.io/socio-aos-component";
import { utils } from "@socotec.io/socio-vue-components";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const allMessages = {
  en: {
    $vuetify: utils.globalVuetify.enGlobalVuetify,
    ...utils.RolesMessages.en,
    ...messages.en,
    ...aosMessages.en
  },
  fr: {
    $vuetify: utils.globalVuetify.frGlobalVuetify,
    ...utils.RolesMessages.fr,
    ...messages.fr,
    ...aosMessages.fr
  }
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: allMessages,
  silentFallbackWarn: true,
  silentTranslationWarn: true
});
