import Portion from "./Portion";
import { BaseModel } from "./baseModels";

export default class PortionObservable extends BaseModel {
    static entity = "portionObservables";

    static fields() {
        return {
            ...super.fields(),
            portionData: this.belongsTo(Portion, "portion"),

            // Foreign keys
            portion: this.string(''),
            aosItem: this.string(''),
            aosObservable: this.string(''),
        };
    }
}