import { socioGrpcClient } from "@/setup/socioGrpcClient";

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchGeneralSettings = async (metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.GeneralSettingsListRequest();
    const response = await socioGrpcClient.atm_back.acoustic.GeneralSettingsControllerPromiseClient.list(
        request,
        metadata
    );
    return response.toObject();
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const createGeneralSettings = async (generalSettingsData, currentCase, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.GeneralSettingsRequest,
        generalSettingsData
    )

    request.setCaseInfo(currentCase.atmUuid)

    const response = await socioGrpcClient.atm_back.acoustic.GeneralSettingsControllerPromiseClient.create(
        request,
        metadata
    )

    return response.toObject()
};

export const updateGeneralSettings = async (generalSettingsData, currentCase, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.GeneralSettingsRequest,
        generalSettingsData
    )
    request.setCaseInfo(currentCase.atmUuid)

    const response = await socioGrpcClient.atm_back.acoustic.GeneralSettingsControllerPromiseClient.update(
        request,
        metadata
    )

    return response.toObject()
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchPortion = async (metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionListRequest

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.list(
        request,
        metadata
    )

    return response.toObject()
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchAllPortion = async (metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionListRequest

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.utils.listAllObjects(
        request,
        metadata
    )
    return response
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const retrievePortion = async (portionUuid, metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionRetrieveRequest()

    request.setUuid(portionUuid)

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.retrieve(
        request,
        metadata
    )

    return response.toObject()
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const createPortion = async (portionData, currentCase, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.PortionRequest,
        portionData
    )

    request.setCaseInfo(currentCase.atmUuid)

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.create(
        request,
        metadata
    )

    return response.toObject()
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const updatePortion = async (portionData, currentCase, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.PortionRequest,
        portionData
    )
    if (!portionData.measurementDocumentUuid) {
        request.setMeasurementDocumentUuid(null) // avoid sending empty string that will be rejected by the server
    }
    request.setUuid(portionData.uuid)
    request.setCaseInfo(currentCase.atmUuid)

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.update(
        request,
        metadata
    )

    return response.toObject()
};

export const BulkUpdatePortionMaterials = async (portionMaterials, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.BulkUpdatePortionMaterialsRequest,
        portionMaterials
    )

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.bulkUpdatePortionMaterials(
        request,
        metadata
    )

    return response.toObject()
}

export const BulkUpdatePortionContributors = async (portionContributors, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.BulkUpdatePortionContributorsRequest,
        portionContributors
    )

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.bulkUpdatePortionContributors(
        request,
        metadata
    )

    return response.toObject()
}

export const BulkUpdatePortionCompanies = async (portionCompanies, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.BulkUpdatePortionCompaniesRequest,
        portionCompanies
    )

    const response = await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.bulkUpdatePortionCompanies(
        request,
        metadata
    )

    return response.toObject()
}

/**
 *
 * @param {*} metadata
 * @returns
 */
export const destroyPortion = async (portionUuid, metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionDestroyRequest()

    request.setUuid(portionUuid)

    await socioGrpcClient.atm_back.acoustic.PortionControllerPromiseClient.destroy(
        request,
        metadata
    )
}

export const fetchPortionObservable = async (metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionAosObservableListRequest()

    const response = await socioGrpcClient.atm_back.acoustic.PortionAosObservableControllerPromiseClient.utils.listAllObjects(
        request,
        metadata
    )

    return response
}

export const batchCreateOrDeletePortionObservables = async (portionObservablesData, metadata = {}) => {
    const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.atm_back.acoustic.PortionAosObservableBatchCreateOrDeleteRequest,
        portionObservablesData
    )

    const response = await socioGrpcClient.atm_back.acoustic.PortionAosObservableControllerPromiseClient.batchCreateOrDelete(
        request,
        metadata
    )

    return response.toObject().resultsList
}

export const createPortionObservable = async (portionObservableData, metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionAosObservableCreateRequest()
    request.setPortion(portionObservableData.portion)
    request.setAosItem(portionObservableData.aosItem)
    const response = await socioGrpcClient.atm_back.acoustic.PortionAosObservableControllerPromiseClient.create(
        request,
        metadata
    )

    return response.toObject()
}

export const destroyPortionObservable = async (portionObservableUuid, metadata = {}) => {
    const request = new socioGrpcClient.atm_back.acoustic.PortionAosObservableDestroyRequest()

    request.setUuid(portionObservableUuid)

    await socioGrpcClient.atm_back.acoustic.PortionAosObservableControllerPromiseClient.destroy(
        request,
        metadata
    )
}