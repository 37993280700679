import { socioGrpcClient } from "@/setup/socioGrpcClient";

const grpcClient = socioGrpcClient.contributors.contributors;

export const roleClient = {
  list: async () => {
    const metadata = {
      filters: JSON.stringify({
        service_id: "atm"
      }),
      pagination: JSON.stringify({
        page: 1,
        pageSize: 100,
      }),
    };

    const request = new grpcClient.RoleListRequest();

    const response =
      await grpcClient.RoleControllerPromiseClient.list(
        request,
        metadata
      );

    return response.toObject().resultsList;
  },
};
