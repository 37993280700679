import { fetchMaterials } from "@/api/materials";
import Material from "@/models/Material.js";
import { objectFieldsCamelToSnakeCase } from "@/utils/helpers";

const state = {
  n_materials: 0,
};

const getters = {
  getAllMaterials: () => {
    return Material.query().get();
  },
  getMaterialsByUUIDs: () => (uuids) => {
    return Material.query().whereIdIn(uuids).get();
  },
  getMaterialsCount: (state) => {
    return state.n_materials;
  },
};

const actions = {
  fetchMaterials: async ({ commit }, { filters, pagination }) => {
    const metadata = {
      filters: JSON.stringify({
        ...objectFieldsCamelToSnakeCase(filters),
      }),
      pagination: JSON.stringify({
        page: pagination?.page,
        page_size: pagination?.itemsPerPage,
      }),
    };
    const response = await fetchMaterials(metadata);
    let materialResponse = response.getResultsList();
    materialResponse = materialResponse.map((material) => {
      return {
        ...material.toObject(),
        metrologicalParameters: material
          .getMetrologicalParameters()
          .toJavaScript(),
      };
    });
    commit("SET_MATERIALS_COUNT", response.getCount());
    await Material.insertOrUpdate({
      data: materialResponse,
    });
    return materialResponse;
  },
};

const mutations = {
  SET_MATERIALS_COUNT(state, count) {
    state.n_materials = count;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
