import { socioGrpcClient } from "@/setup/socioGrpcClient";

export const fetchMaterials = async (metadata = {}) => {
  const request =
    new socioGrpcClient.materials.material_services.MaterialListRequest();
  const response =
    await socioGrpcClient.materials.material_services.MaterialControllerPromiseClient.list(
      request,
      metadata
    );
  return response;
};