import { socioGrpcClient } from "@/setup/socioGrpcClient";

const grpcClient = socioGrpcClient.contributors.contributors;

export const companyProjectRoleClient = {
    list: async (metadata) => {
        const request = new grpcClient.CompanyProjectRoleListRequest();

        const response =
          await grpcClient.CompanyProjectRoleControllerPromiseClient.utils.listAllObjects(
            request,
            metadata
          );

        return response
    },
    create: async (companyProjectRole) => {
        const request = socioGrpcClient.javascriptToRequest(
          grpcClient.CompanyProjectRoleRequest,
          companyProjectRole
        );

        const response =
          await grpcClient.CompanyProjectRoleControllerPromiseClient.getOrCreate(
            request,
            {}
          );
        
        return response.toObject();
    },
    update: async (companyProjectRole) => {
        const request = socioGrpcClient.javascriptToRequest(
          grpcClient.CompanyProjectRoleRequest,
          companyProjectRole
        );

        const response =
          await grpcClient.CompanyProjectRoleControllerPromiseClient.update(
            request,
            {}
          );

        return response.toObject();
    },
    destroy: async (companyProjectRole) => {
        const request = socioGrpcClient.javascriptToRequest(
          grpcClient.CompanyProjectRoleDestroyRequest,
          companyProjectRole
        );

        await grpcClient.CompanyProjectRoleControllerPromiseClient.destroy(
          request,
          {}
        );
        
        return null;
    },
};