import { Role } from "@socotec.io/socio-vue-components";
import { roleClient } from "@/api/contributors";

const state = {};

const getters = {
  externalRoles: () => Role.query().where("roleType", "EXTERNAL").get(),
  internalRoles: () => Role.query().where("roleType", "INTERNAL").get(),
};

const actions = {
  async fetchRoles() {
    const rolesData = await roleClient.list();

    Role.deleteAll();

    const roles = Role.insert({ data: rolesData });

    return roles;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
