import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { camelToSnakeCase } from "@/utils/helpers";
const excludedKeys = ["createdAt", "updatedAt"];

// == ========== ==
// == PERMEA API ==
// == ========== ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchPermeaTag = async (metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.permea.PermeaTagListRequest,
    {}
  );
  const response = await socioGrpcClient.atm_back.permea.PermeaTagControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchPermeaCalculationLine = async (metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.permea.PermeaCalculationLineListRequest,
    {}
  );
  const response = await socioGrpcClient.atm_back.permea.PermeaCalculationLineControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} permeaCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const createPermeaCalculationLine = async (
  permeaCalculationLineData,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.permea.PermeaCalculationLineRequest,
    {
      ...permeaCalculationLineData
    }
  );
  const response = await socioGrpcClient.atm_back.permea.PermeaCalculationLineControllerPromiseClient.create(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} permeaCalculationLineDataUuid
 * @param {*} metadata
 * @returns
 */
export const destroyPermeaCalculationLine = async (uuid, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.permea.PermeaCalculationLineDestroyRequest,
    {
      uuid
    }
  );
  const response = await socioGrpcClient.atm_back.permea.PermeaCalculationLineControllerPromiseClient.destroy(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} permeaCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const updatePermeaCalculationLine = async (
  permeaCalculationLineData,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.permea.PermeaCalculationLinePartialUpdateRequest,
    {
      ...permeaCalculationLineData
    }
  );
  request.setPartialUpdateFieldsList(
    Object.keys(permeaCalculationLineData).filter(key => !excludedKeys.includes(key)).map(key => {
      const toSnake = camelToSnakeCase(key);
      return toSnake.endsWith("_list") ? toSnake.replace("_list", "") : toSnake;
    })
  );
  const response = await socioGrpcClient.atm_back.permea.PermeaCalculationLineControllerPromiseClient.partialUpdate(
    request,
    metadata
  );
  return response.toObject();
};
