import { contributorClient } from "@/api/contributors";
import { Contributor } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {
  contributorsAccompanist: () => {
    return Contributor.query()
      .with("companyProjectRole.company")
      .with("companyProjectRole.role", (query) => {
        query.where("roleType", "EXTERNAL");
      })
      .where("isAccompanist", true)
      .get();
  },
  contributorsMandatoryRecipient: () => {
    return Contributor.query()
      .with("companyProjectRole.company")
      .with("companyProjectRole.role", (query) => {
        query.where("roleType", "EXTERNAL");
      })
      .where("isMandatoryRecipient", true)
      .get();
  },
  getContributors: () => Contributor.query().with('companyProjectRole.role').with('companyProjectRole.company').get(),
  getTechnicalControlContributors: () => Contributor.query()
    .with('companyProjectRole.role')
    .with('companyProjectRole.company')
    .whereHas('companyProjectRole', (query) => {
      query.whereHas('role', (roleQuery) => {
        roleQuery.where('name', "CONTROLEUR_TECHNIQUE")
      })
    })
    .get(),
  getBetAcousticContributors: () => Contributor.query()
    .with('companyProjectRole.role')
    .with('companyProjectRole.company')
    .whereHas('companyProjectRole', (query) => {
      query.whereHas('role', (roleQuery) => {
        roleQuery.where('name', 'BET_ACOUSTIQUE')
      })
    })
    .get(),
  getContributorsByUuids: () => (uuids = []) =>
    Contributor.query()
      .with('companyProjectRole.role|company')
      .whereIdIn(uuids)
      .get(),
  getContributorByUUID: () => (uuid) =>
    Contributor.query()
      .with('companyProjectRole.role')
      .with('companyProjectRole.company')
      .whereId(uuid)
      .first()
};

const actions = {
  async createContributor(context, contributor) {
    const contributorData = await contributorClient.create(contributor);

    await Contributor.insert({ data: contributorData });

    return contributorData;
  },

  async updateContributor(context, contributor) {
    const contributorData = await contributorClient.update(contributor);

    await Contributor.update({
      data: [contributorData],
    });

    return contributorData;
  },

  async destroyContributor(context, contributor) {
    await contributorClient.destroy(contributor);

    await Contributor.delete(contributor.uuid);
  },

  async fetchContributors(context, metadata = {}) {
    const contributorsData = await contributorClient.list(metadata);

    Contributor.deleteAll();

    const { contributor } = await Contributor.insert({ data: contributorsData });

    return contributor;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
