import { socioGrpcClient } from "@/setup/socioGrpcClient";

// == ============ ==
// ** CaseInfo API **
// == ============ ==

/**
 *
 * @param {*} caseNumber
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const retrieveCaseInfoByCaseNumber = async (
  caseNumber,
  metadata = {},
  failIfNoFound = false
) => {
  try {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.atm_back.atm.RetrieveCaseByCaseNumberMessage,
      { caseNumber }
    );
    const response = await socioGrpcClient.atm_back.atm.CaseInfoControllerPromiseClient.retrieveCaseByCaseNumber(
      request,
      metadata
    );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const createCaseInfo = async (caseData, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.atm.CaseInfoRequest();
  request.setCaseNumber(caseData.caseNumber);
  request.setAtmLabel(caseData.atmLabel);
  request.setDescription(caseData.description);
  request.setAtmPostcode(caseData.atmPostcode);
  request.setAtmAddress(caseData.atmAddress);
  request.setAtmCity(caseData.atmCity);

  const response = await socioGrpcClient.atm_back.atm.CaseInfoControllerPromiseClient.create(
    request,
    metadata
  );

  return response.toObject();
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const updateCaseInfo = async (caseData, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.atm.CaseInfoRequest();
  request.setUuid(caseData.atmUuid);
  request.setCaseNumber(caseData.caseNumber);
  request.setAtmLabel(caseData.atmLabel);
  request.setDescription(caseData.description);
  request.setAtmPostcode(caseData.atmPostcode);
  request.setAtmAddress(caseData.atmAddress);
  request.setAtmCity(caseData.atmCity);
  request.setComment(caseData.comment);
  request.setGroupedPermit(caseData.groupedPermit);
  request.setBuildPermitNumber(caseData.buildPermitNumber);
  request.setThermalRegulation(caseData.thermalRegulation);
  request.setBuildPermitSubmissionDate(caseData.buildPermitSubmissionDate);
  request.setBuildPermitIssuanceDate(caseData.buildPermitIssuanceDate);

  const response = await socioGrpcClient.atm_back.atm.CaseInfoControllerPromiseClient.update(
    request,
    metadata
  );

  return response.toObject();
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const synchroniseCaseMissions = async (
  caseData,
  force,
  metadata = {}
) => {
  const request = new socioGrpcClient.atm_back.atm.CaseInfoSynchroniseCaseMissionsRequest();
  request.setUuid(caseData.atmUuid);
  request.setForce(force);

  const response = await socioGrpcClient.atm_back.atm.CaseInfoControllerPromiseClient.synchroniseCaseMissions(
    request,
    metadata
  );

  return response.toObject();
};

export const fetchCases = async (metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.atm.CaseInfoListRequest,
    {}
  );
  const response = await socioGrpcClient.atm_back.atm.CaseInfoControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
};