import { socioGrpcClient } from "@/setup/socioGrpcClient";

const grpcClient = socioGrpcClient.contributors.contributors;

export const companyClient = {
  listAllObjects: async (metadata) => {
    const request = new grpcClient.CompanyListRequest();

    const response =
      await grpcClient.CompanyControllerPromiseClient.utils.listAllObjects(
        request,
        metadata
      );

    return response;
  },
  list: async (metadata) => {
    const request = new grpcClient.CompanyListRequest();

    const response = await grpcClient.CompanyControllerPromiseClient.list(request, metadata);
    
    return response.getResultsList().map(company => company.toObject());
  },
  create: async (company) => {
    const request = socioGrpcClient.javascriptToRequest(
      grpcClient.CompanyRequest,
      company
    );

    const response = await grpcClient.CompanyControllerPromiseClient.create(
      request,
      {}
    );

    return response.toObject();
  },
  update: async (company) => {
    const request = socioGrpcClient.javascriptToRequest(
      grpcClient.CompanyRequest,
      company
    );

    const response = await grpcClient.CompanyControllerPromiseClient.update(
      request,
      {}
    );

    return response.toObject();
  },
};
