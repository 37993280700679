import { companyClient } from "@/api/contributors";
import { Company } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {
  companies: () => Company.query().all(),
};

const actions = {
  async createCompany(context, company) {
    const companyData = await companyClient.create(company);

    await Company.insert({ data: companyData });

    return companyData;
  },

  async updateCompany(context, company) {
    const companyData = await companyClient.update(company);

    await Company.update({ data: [companyData] });

    return companyData;
  },
  async fetchAllCompanies(context, { metadata = {}, withCache = true }) {
    const companiesData = await companyClient.listAllObjects(metadata);

    if (!withCache) return companiesData;

    Company.deleteAll();

    const { company } = await Company.insert({ data: companiesData });

    return company;
  },
  async fetchCompanies(context, { metadata = {}, withCache = true }) {
    const companiesData = await companyClient.list(metadata);

    if (!withCache) return companiesData;

    Company.deleteAll();

    const { company } = await Company.insert({ data: companiesData });

    return company;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
