import { Material as BaseMaterial } from "@socotec.io/socio-vue-components";

export default class Material extends BaseMaterial {
  static entity = "material";
  static primaryKey = "uuid";
  static baseEntity = "baseMaterial";

  static fields() {
    return {
      ...super.fields(),
      agencyUuid: this.string(""),
      agencyCode: this.string(""),
      locality: this.string(""),
      expirationDate: this.attr(""),
      metrologicalParameters: this.attr({}),
    };
  }
}
