import { socioGrpcClient } from "@/setup/socioGrpcClient";

// == ============ ==
// ** Document API **
// == ============ ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const retrieveDocument = async (
  uuid,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request =
      new socioGrpcClient.document.document.DocumentCustomRetrieveRequest();
    request.setUuid(uuid);
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendRetrieve(
        request,
        metadata
      );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

/**
 *
 * @param {*} uuid
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const retrieveRetrieveDocumentUrl = async (
  uuid,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request =
      new socioGrpcClient.document.document.DocumentCustomRetrieveRequest();
    request.setUuid(uuid);
    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.retrieveDocumentCustomUrl(
        request,
        metadata
      );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDocuments = async (metadata = {}) => {
  const request =
    new socioGrpcClient.document.document.DocumentCustomListRequest();
  const response =
    await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.documentCustomExtendList(
      request,
      metadata
    );
  return response;
};
/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDocumentsFromObjectStorage = async (metadata = {}) => {
  const request =
    new socioGrpcClient.document.document.DocumentObjectStorageListRequest();
  const response =
    await socioGrpcClient.document.document.DocumentControllerPromiseClient.listDocumentInObjectStorage(
      request,
      metadata
    );
  return response.toObject();
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const createManualDocument = async (docData, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.document.document.DocumentFlatRequest,
    docData
  );
  const response =
    await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.createWithDocument(
      request,
      metadata
    );

  return response;
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const createBimdataDocument = async (doc, docCustom, file, fileName, callback) => {
  return await socioGrpcClient.document.document.StandbyUploadControllerPromiseClient.utils.uploadDocument(
    doc,
    docCustom,
    file,
    fileName,
    callback
  );
};

export const updateDocument = async (documentUuid, docData, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.document.document.DocumentFlatRequest,
    docData
  );
  request.setUuid(documentUuid);
  const response =
    await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.updateDocumentCustomExtend(
      request,
      metadata
    );
  return response;
}

/**
 *
 * @param {*} documentUuid
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const destroyDocument = async (
  documentUuid,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request =
      new socioGrpcClient.document.document.DocumentCustomDestroyRequest();

    request.setUuid(documentUuid);

    const response =
      await socioGrpcClient.document.document.DocumentCustomControllerPromiseClient.destroy(
        request,
        metadata
      );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

// == ============ ==
// ** TAG API **
// == ============ ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDocumentTags = async (metadata = {}) => {
  const request = new socioGrpcClient.document.document.TagListRequest();
  const response =
    await socioGrpcClient.document.document.TagControllerPromiseClient.list(
      request,
      metadata
    );
  return response.toObject();
};

// DOCUMENT TYPE API

export const listDocumentTypes = async () => {
  const request =
    new socioGrpcClient.document.document.DocumentTypeListRequest();
  const response =
    await socioGrpcClient.document.document.DocumentTypeControllerPromiseClient.utils.listAllObjects(
      request,
      {}
    );
  return response;
};

// DOCUMENT STATUS API

export const listDocumentStatus = async () => {
  const request =
    new socioGrpcClient.document.document.DocumentStatusListRequest();
  const response =
    await socioGrpcClient.document.document.DocumentStatusControllerPromiseClient.utils.listAllObjects(
      request,
      {}
    );
  return response;
};
